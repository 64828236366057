<template>
	<table-page
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="typesofdiscount3485"
		tooltipMsg="pimanualoftypes"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'DiscountTypeId', ascending: 0 }"
		modelId="DiscountTypeId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	></table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';

import { tableColumns } from './discount-type-table.data';

export default {
	name: 'DiscountTypeTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'DiscountType',
			columns: tableColumns,
		};
	},

	methods: {
		redirectToDetail(id = 0) {
			this.$router.push({
				name: links.discountTypeDetailName,
				params: { id },
			});
		},
	},
};
</script>